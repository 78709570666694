<template>
<div ref="top" class="module" :class="{ desktopAlign: $vuetify.breakpoint.mdAndUp }">
    <v-btn text class="zindex-2 p-fixed top20 left5 pa-1" @click="back()" v-if="$vuetify.breakpoint.xsOnly">
        <v-icon>mdi-chevron-left</v-icon>
        Back
    </v-btn>

    <meo-ripple ref="top" :config="config" v-if="$vuetify.breakpoint.smAndDown"></meo-ripple>

    <v-container :class="[$vuetify.breakpoint.mdAndUp ? `pa-9 pt-12` : `pa-11 pb-3`]">
        <v-row class="p-relative zindex-1">
            <v-col cols="12" align="center" justify="center">
                <h3 class="heading--text">{{ title }}</h3>
                <p class="muted-1 ma-0">
                    {{ description }}
                </p>
            </v-col>
        </v-row>
        <v-row v-if="byline">
            <v-col cols="12" align="center" justify="center">
                <p class="muted-1">{{ byline }}</p>
            </v-col>
        </v-row>
    </v-container>

    <div class="screen-container" :class="{ desktop: $vuetify.breakpoint.mdAndUp }">
        <template v-if="!loading">
            <v-container class="pa-0 full-width" :class="contentClass">
                <transition name="slide-y-transition" mode="out-in" appear>
                    <v-row class="pa-3 flex-shrink-1 flex-grow-0" v-if="!isMenu && $vuetify.breakpoint.mdAndUp">
                        <v-col class="pb-1" :class="{ 'pt-1': $vuetify.breakpoint.smAndDown }">
                            <meo-progress-tracker v-if="moduleLength > 1" hide-title @next="next" :module="module" vertical :items="sections" :screens="screens" :key="cache"></meo-progress-tracker>
                        </v-col>
                    </v-row>
                </transition>

                <transition name="slide-y-transition" mode="out-in" appear>
                    <router-view :screens="screens" :sections="sections" :progressDoc="progressDoc" :module="module" :contact="contact" @next="next" @notes="notes" @update="update" :key="routerCache" />
                </transition>
            </v-container>
        </template>
    </div>

    <alert-dialog ref="alertDialog" title="Send to Global Leadership Foundation" action-label="Send" cancel-label="Skip" action-color="primary" cancel-color="grey" @confirm="confirm" @cancel="cancel"></alert-dialog>

    <transition name="slide-y-transition" mode="out-in" appear>
        <v-container class="float" v-if="!isMenu && $vuetify.breakpoint.smAndDown">
            <v-row>
                <v-col class="pb-3 pt-0">
                    <meo-progress-tracker v-if="moduleLength > 1" hide-title @next="next" :module="module" :items="sections" :screens="screens" :key="cache"></meo-progress-tracker>
                </v-col>
            </v-row>
        </v-container>
    </transition>
</div>
</template>

<script>
//neuicons

// Mixins
import UserMixin from "@/mixins/user-mixin";
import SEOMixin from "@/mixins/seo-mixin";

// Components
import MeoRipple from "@/components/misc/meo-ripple.vue";
import MeoListItem from "@/components/misc/meo-list-item.vue";
import MeoProgressTracker from "@/components/misc/meo-progress-tracker.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";
import MeoMeditationPlayer from "@/components/misc/meo-meditation-player.vue";

// Services
import Module from "@/modules/meo-module/module";
import ModuleScreen from "@/modules/meo-module/module-screen";
import Contact from "@/modules/meo-module/contact";
import MeoProgressDocument from "@/modules/meo-module/progress-document";

export default {
    name: "module",

    mixins: [UserMixin, SEOMixin],

    meta: {
        titleTemplate: `Sessions | %s`,
    },

    components: {
        MeoRipple,
        MeoListItem,
        MeoProgressTracker,
        AlertDialog,
        MeoMeditationPlayer,
    },

    data() {
        return {
            module: {},
            contact: {},
            cache: 0,
            routerCache: 0,
            progressDoc: {},
            buttonControl: false,
            loaded: false,
            screens: [],
            loading: true,
        };
    },

    computed: {
        moduleLength() {
            let length = this.module?.items?.length || 0;
            return length;
        },
        config() {
            let config = {
                padding: true,
            };

            if (this.module && this.module.data && this.module.data.image) {
                config.image = this.$fluro.asset.imageUrl(this.module.data.image);
            }

            return config;
        },
        title() {
            return (this.module && this.module.title) || "";
        },
        description() {
            return (this.module && this.module.data && this.module.data.description) || "";
        },
        sections() {
            return (this.module && this.module.items) || [];
        },
        isMenu() {
            return this.$route.name == "module.menu";
        },
        moreThanOne() {
            return this.screens && this.screens?.length > 1;
        },
        contentClass() {
            if (this.$route.name !== "module.menu" && this.$vuetify.breakpoint.mdAndUp) {
                return "d-flex max-width-80";
            } else {
                return "d-flex full-width";
            }
        },
        byline() {
            if (this.module?.data?.byline) {
                return this.module.data.byline;
            } else {
                return "";
            }
        },
    },

    methods: {
        async init() {
            this.$meo.loading = true;
            let current;

            let session = await this.$fluro.api.get("/session", { cache: false }).then(({ data }) => data);

            if (this.$meo.user) {
                // We have a user, and we assume they have purchased the product,
                // so get the actual product from our list of allowed products
                await this.$fluro.resetCache();
                let products = await this.$fluro.api.get("/my/products/meoModuleScreen").then(({ data }) => data);

                current = products.find((product) => product._id === this.$route.params.id);

                this.contact = {};

                if (session.contacts[0]) {
                    this.contact = await Contact.get(session.contacts[0]).then(({ data }) => data);
                    this.$localProgress.getLocalFavourites(this.contact);
                }

                if (!current) {
                    current = await this.getFreeModule();
                }
            } else {
                current = await this.getFreeModule();
            }

            this.module = current;
            this.$emit("imageToNav", current);

            if (this.sections && this.sections.length && this.sections.length === 1) {
                let section = this.sections[0];
                this.$router.push({
                    name: "module.screen",
                    params: { id: this.$route.params.id, screenId: section._id },
                });
            }

            this.loading = false;

            let progress = await this.progressDocGrab();
            this.progressDoc = progress;
            let progressModule = (progress && progress.data && progress.data.modules) || [];

            this.$localProgress.storeLocalProgressToCreatedProgressDoc(this.progressDoc, this.module);

            if (progressModule?.length) {
                let currentModule = progressModule.find((progress) => this.module._id == progress.module._id);

                let screens = (currentModule && currentModule.screens) || [];
                this.screens = screens;

                this.cache++;
            } else {
                this.getProgressLocalStorage();
            }
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.top.scrollIntoView({ behaviour: "smooth" });
            }

            this.$meo.loading = false;
        },
        async getFreeModule() {
            // No user, assuming it's a free product, so get the product and then populate the screens
            let current = await Module.get(this.$route.params.id).then(({ data }) => data);

            let ids = current.items.map(({ _id }) => _id);
            let items = await this.$fluro.content.getMultiple("", ids, {});

            current.items = items;

            return current;
        },
        getProgressLocalStorage() {
            let localProgress = JSON.parse(window.localStorage.getItem("progress"));
            if (!this.contact._id) {
                if (localProgress) {
                    let index = localProgress.findIndex((item) => item.module._id === this.module._id);
                    if (index !== -1) {
                        this.screens = localProgress[index].screens;
                    }
                }
            }
        },
        next(section) {
            let id;

            if (section && section._id) id = section._id;
            else id = section;
            // Update contact here
            this.getProgressLocalStorage();
            this.routerCache++;
            this.cache++;
            this.$router.push({
                path: `/sessions/${this.$route.params.id}/screen/${id}`,
            });
        },
        notes() {
            this.getProgressLocalStorage();
            this.cache++;

            this.$router.push({
                name: "module.notes",
                params: { id: this.$route.params.id },
            });
        },
        back() {
            if (this.$route.name == "module.menu") {
                this.$router.push({ name: "modules" });
            } else if (this.module.items.length <= 1) {
                this.$router.push({ name: "modules" });
            } else {
                this.$router.push({
                    name: "module.menu",
                    params: { id: this.$route.params.id },
                });
            }
        },
        async update(progressDoc) {
            // input is the updated progress Doc with the newly completed section as the progressDoc
            console.log("updated running", progressDoc);
            let progress = progressDoc;

            let progressModule = (progress && progress.data && progress.data.modules) || [];

            // if the array of modules on the progressDoc has a length
            if (progressModule.length) {
                // try to find the module that matches the current module
                let currentModule = progressModule.find((progress) => this.module._id == progress.module._id);

                // update the current value for screens completed with the current modules completed screens array
                let screens = (currentModule && currentModule.screens) || [];
                this.screens = screens;

                this.cache++;
                this.progressDoc = progressDoc;
                await MeoProgressDocument.update(progressDoc._id, progressDoc).then(({ data }) => data);
            }
        },

        cancel() {
            this.target = 0;
            this.completed = true;
        },
        async confirm() {
            // Send an email to Global Leadership with the notes model
            let payload = {
                user: this.$meo.user,
                notes: this.notes,
            };

            // await Reaction

            this.$refs.alertDialog.close();

            this.target = 0;
            this.completed = true;
        },
        progressCheck(show) {
            this.buttonControl = show;
        },
        async progressDocGrab() {
            let progressDocument = await MeoProgressDocument.list().then(({ data }) => data);

            if (progressDocument && progressDocument.length) {
                window.localStorage.removeItem("progress");
                console.log(progressDocument[0], "the Progress doc...");
                return progressDocument[0];
            } else {
                console.log("ProgressDoc does NOT exist...");
            }
        },
    },

    async created() {
        await this.init();
    },
};
</script>

<style lang="scss">
.module {
    margin-top: 24px;
    .float {
        position: fixed !important;
        bottom: 57px;
        width: 100%;
        z-index: 5;
    }

    .screen-container {
        &.desktop {
            display: flex;
            align-items: flex-start;
            width: 100%;
        }
    }

    @media (max-width: 960px) {
        margin-bottom: 52px;
    }
}

.max-width-80 {
    max-width: 80% !important;
}

.desktopTitle {
    font-size: 36px;
    margin-bottom: 8px;
}

.desktopDescription {
    font-size: 24px;
}

.byline {
    font-size: 20px;
    font-weight: 400;
    color: rgb(65, 65, 60);
    text-align: center;
    max-width: 60%;
    line-height: 1.8;
    font-style: italic;
    margin-bottom: 0 !important;
}

.byline-mobile {
    font-size: 14px;
    font-weight: 400;
    color: rgb(65, 65, 60);
    text-align: center;
    margin-bottom: 0 !important;
    line-height: 1.8;
    font-style: italic;
}
</style>
